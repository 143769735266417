.landing-main{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: relative;

    .landing-main-img-wrap{
        max-width: 400px;
        max-height: 400px;
        overflow: hidden;

        img{
            width: 100%;
            width: 100%;
        }

        margin-bottom: 20px;
    }

    .landing-main-title-wrap{
        text-align: center;
        margin-top: 30px;
        margin-bottom: 20px;

        p{
            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 55px;
            color: #FFFFFF;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
    }

    .landing-main-help-text-wrap{
        text-align: center;
        margin-bottom: 20px;

        p{
            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            color: #FFFFFF;
        }
    }

    .landing-main-btns-wrap{
        display: flex;
        flex-direction: row;

        a{
            text-decoration: none;
        }

        .landing-main-btn{
            margin: 0 15px;
            padding: 14px 26px;
            min-width: 200px;
            background: #232323;
            box-shadow: 0px 0px 10px rgba(44, 39, 109, 0.1);
            border-radius: 10px;
            cursor: pointer;
            text-align: center;

            p{
                font-family: "Inter", sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                color: #FFFFFF;
            }
        }
    }
}

@media screen and (max-width: 560px) {
    .landing-main{
        .landing-main-title-wrap{
            p{
                font-size: 40px;
            }
        }   
        
        .landing-main-help-text-wrap{
            p{
                font-size: 16px;
            }
        }
    }
}


@media screen and (max-width: 465px) {
    .landing-main{
        .landing-main-btns-wrap{
            flex-direction: column;

            .landing-main-btn{
                margin: 15px;
            }
        }
    }
}