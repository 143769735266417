.wrap-token-poper{
    width: 100%;

    .wrap-cros-wrap{
        align-items: center;
        justify-content: space-between;

        .wrap-price{
            cursor: pointer;
            p{
                font-family: "Inter", sans-serif;
                font-style: normal;
                font-size: 14px;
                color: #FFFFFF;
            }
        }
    }

    .wrap-header-conteiner{
        margin: 10px 30px 0 30px; 
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .wrap-header-title{
            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            color: #FFFFFF;
        }

        .wrap-header-balance{
            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: rgba(255, 255, 255, 0.6);
        }
    }

    .wrap-container{
        margin: 30px 30px 20px 30px;
        width: auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        .wrap-input{
            margin: 0 !important;

            .wrap-action-input-btn{
                cursor: pointer;

                p{
                    font-family: Montserrat;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    color: #FFFFFF;
                }
            }

            .wrap-action-input-text{
                font-family: "Inter", sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                color: rgba(255, 255, 255, 0.6);
            }
        }

        .wrap-toggle{
            margin: 5px 0 5px 0;
        }

        .wrap-btn{
            cursor: pointer;
            margin-top: 20px;
            background: rgba(255, 255, 255, 0.2);
            box-shadow: 0px 0px 10px rgba(44, 39, 109, 0.1);
            border-radius: 10px;
            padding: 14px 0;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            
            p{
                font-family: Montserrat;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                color: #FFFFFF;
            }
        }

        .wrap-help-text{
            margin-top: 20px;

            p{
                font-family: "Inter", sans-serif;
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                text-align: center;
                color: rgba(255, 255, 255, 0.6);
            }
        }
    }
}
