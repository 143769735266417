.not-found-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  p{
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 30px;
    color: #FFFFFF;
  }
}
