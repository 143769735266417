.dashboard-view {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;

  width: 89%;
  max-width: 833px;
  margin: auto;

  .dashboard-infos-wrap{
    .dashboard-card{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border: 1px solid aliceblue;
      position: relative;
      z-index: 2;
      
      @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        background: rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(100px);
      }
    
      /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
      @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
        background: #181B1C;
      }

      border-radius: 10px;
      padding: 13px 0;

      .card-title {
        font-family: "Inter", sans-serif;
        font-weight: 500;
        font-size: 20px;
        color: rgba(255, 255, 255, 0.6);
      }
  
      .card-value {
        font-family: "Inter", sans-serif;
        font-weight: bold;
        font-size: 24px;
        font-weight: bold;
        color: #FFFFFF;
        word-break: break-all;
      }
    }
  }
}