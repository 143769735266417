.time-menu-root{
  .time-menu-btn{
    padding: 9px 20px;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0px 0px 10px rgba(44, 39, 109, 0.1);
    border-radius: 10px;
    cursor: pointer;
    margin-right: 20px;

    p{
      font-family: "Inter", sans-serif;
      font-weight: 600;
      font-size: 18px;
      color: #FFFFFF;
    }
  }
}

.time-menu-popper{
  margin-top: 10px;

  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(100px);
  }

  /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
  @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
      background: #181B1C;
  }

  border-radius: 10px;
  overflow: hidden;
  z-index: 10;

  .tooltip{
    display: flex;
    flex-direction: column;

    .tooltip-item{
      text-decoration: none;
      padding: 13px 20px;
      cursor: pointer;

      &:hover{
          background: rgba(255,255,255,0.1);
      }

      p{
          font-family: "Inter", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: #FFFFFF;
          text-align: center;
      }
    }

    .add-tokens{
      display: flex;
      flex-direction: column;

      .add-tokens-title{
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #FFFFFF;
        text-align: center;

        margin: 5px 0px;
      }
    }

    .divider{
      height: 1px;
      background: #FFFFFF;
      border: none;
    }
  }
}