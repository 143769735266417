.wrap-button{
    padding: 9px 20px;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0px 0px 10px rgba(44, 39, 109, 0.1);
    border-radius: 10px;
    cursor: pointer;
    margin-right: 20px;

    p{
      font-family: "Inter", sans-serif;
      font-weight: 600;
      font-size: 18px;
      color: #FFFFFF;
    }
}